import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { capitalizeFirstLetter, generateAffiliateTrackingUrl } from '../../utils/helpers'

const AffiliateTracking = (props) => {
  const {
    platform,
    uid,
    integration_id,
    webhookUrlBase,
    connectorUrlSuffix,
    connectorUrlParams,
    urlLabel,
    urlEventTypes,
    isLoadingConnectors,
    connectors,
    setMessage,
    setShowMessage,
    copyToClipboard,
    instructionsNode,
  } = props

  const theme = useTheme()

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant='h6'>
                Affiliate Tracking
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box display='block'>
                <Typography variant='body2' paragraph>
                  If you want to track affiliate sales from your contact lists, such as in Email Platforms,
                  here you can see a list of all Contact Connectors that have been created in supported Data Sources.
                </Typography>

                <Typography variant='h6' gutterBottom>
                  Set Up Instructions
                </Typography>
                {instructionsNode}

                <Typography variant='h6'>
                  Tracking Link Structure (Critically Important)
                </Typography>
                <Typography variant='body2' paragraph>
                  In order to properly track affiliates sales through {capitalizeFirstLetter(platform)}, your link structure must follow a strict format to ensure
                  the necessary data is available to attribute the sale back to your contacts. If you do not follow this format, tracking will not work.
                </Typography>
                <Typography variant='body2' paragraph>
                  With this in mind, for each connector below, we provide you with tracking parameter templates. These are meant to be ADDED on to
                  any other parameters you may already have in your tracking links.
                </Typography>
                <Typography variant='body2' paragraph>
                  The <b>Tracking Parameters - Required</b> provides the minimum required to attribute sales to contacts.
                  The <b>Tracking Parameters - All</b> provides all available tracking fields that you can use to filter and group your data inside LTV Numbers.
                </Typography>
                <Typography variant='body2' paragraph>
                  In the tracking links, you will need to set values for the ALL_CAPS values after the equals signs that have the prefix &quot;SET_&quot;. Everything else should be left exactly as-is to ensure proper behavior.
                  Note that for the optional fields in the tracking link, it is acceptable to leave values blank, or to remove unneeded parameters entirely.
                </Typography>
                <Typography variant='body2' paragraph>
                  If you are not familiar with how to properly structure URL Parameters, you can read more about it <a href='https://www.semrush.com/blog/url-parameters/' target='_blank' rel='noreferrer'>here</a>, or you can reach out to your LTV Numbers
                  expert for assistance.
                </Typography>

                {isLoadingConnectors ? (
                  <Skeleton variant='text' width='100%' />
                ) : (
                  <Box>
                    <Typography variant='h6'>Contact Connectors</Typography>
                    {connectors.length > 0 ? (
                      <>
                        {connectors.map((connector, index) => {
                          const connectorWebhookUrl = webhookUrlBase + uid + '/integration/' + integration_id + '/connector/' + connector.id + '/' + connectorUrlSuffix + `${connectorUrlParams ? '?' + connectorUrlParams : ''}`
                          const trackingLinks = generateAffiliateTrackingUrl(connector.integration.platform, platform)

                          const renderFields = (fields, indentLevel = 0) => {
                            return Object.keys(fields).map((key) => {
                              const value = fields[key]
                              const indent = '== '.repeat(indentLevel)
                              if (value.nested) {
                                return (
                                  <Box key={key}>
                                    <Typography variant='body2' display='block' fontWeight='bold'>
                                      {indent}{key}:
                                    </Typography>
                                    {renderFields(value.nested, indentLevel+1)}
                                  </Box>
                                )
                              } else {
                                return (
                                  <Typography key={key} variant='body2' display='block'>
                                    {indent}<b>{key}</b>: [{value.status}] {value.description}
                                  </Typography>
                                )
                              }
                            })
                          }

                          return (
                            <Box
                              key={connector.id}
                              marginTop={1}
                              marginBottom={1}
                              border={1}
                              borderColor={theme.palette.divider}
                              borderRadius={1}
                              padding={2}
                            >
                              <Box key={index} display='flex' alignItems='center'>
                                <Typography variant='body1' fontWeight='bold'>{connector.integration.nickname}&nbsp;</Typography>
                                <Typography variant='body1'>({capitalizeFirstLetter(connector.integration.platform)})</Typography>
                              </Box>

                              {/* Connector  URL */}
                              {urlEventTypes && urlEventTypes.length > 0 ? urlEventTypes.map(eventType => {
                                const connectorWebhookUrlWithEvent = webhookUrlBase + uid + '/integration/' + integration_id + '/connector/' + connector.id + '/' + connectorUrlSuffix + '/' + eventType + `${connectorUrlParams ? '?' + connectorUrlParams : ''}`
                                return (
                                  <Box key={eventType} marginTop={2}>
                                    <Box>
                                      <Typography variant='body2' fontWeight='bold'>
                                        {urlLabel} ({eventType})
                                      </Typography>
                                    </Box>
                                    <Box key={eventType} display='flex' flexDirection='row' width='100%'>
                                      <InputBase
                                        variant='filled'
                                        value={connectorWebhookUrlWithEvent}
                                        size='small'
                                        multiline
                                        fullWidth
                                        disabled
                                        sx={{
                                          wordBreak: 'break-all',
                                          overflowWrap: 'break-word'
                                        }}
                                      />
                                      <Button
                                        variant='text'
                                        color='primary'
                                        onClick={() => {
                                          setMessage('Copied to clipboard')
                                          setShowMessage(true)
                                          copyToClipboard(connectorWebhookUrlWithEvent)
                                        }}
                                      >
                                        Copy
                                      </Button>
                                    </Box>
                                  </Box>
                                )
                              }) : (
                                <Box marginTop={2}>
                                  <Box marginTop={2}>
                                    <Typography variant='body2' fontWeight='bold'>
                                      {urlLabel}
                                    </Typography>
                                  </Box>
                                  <Box display='flex' flexDirection='row' width='100%'>
                                    <InputBase
                                      variant='filled'
                                      value={connectorWebhookUrl}
                                      size='small'
                                      multiline
                                      fullWidth
                                      disabled
                                      sx={{
                                        wordBreak: 'break-all',
                                        overflowWrap: 'break-word'
                                      }}
                                    />
                                    <Button
                                      variant='text'
                                      color='primary'
                                      onClick={() => {
                                        setMessage('Copied to clipboard')
                                        setShowMessage(true)
                                        copyToClipboard(connectorWebhookUrl)
                                      }}
                                    >
                                      Copy
                                    </Button>
                                  </Box>
                                </Box>
                              )}

                              {/* Tracking Fields */}
                              <Box marginTop={1}>
                                <Typography variant='body2' fontWeight='bold'>
                                  Tracking Parameters
                                </Typography>
                              </Box>
                              <Box marginTop={1}>
                                {renderFields(trackingLinks.fields)}
                              </Box>

                              {/* Connector Tracking Parameters - Required */}
                              <Box marginTop={2}>
                                <Typography variant='body2' fontWeight='bold'>
                                  Tracking Parameters - Required
                                </Typography>
                              </Box>
                              <Box display='flex' flexDirection='row' width='100%'>
                                <InputBase
                                  variant='filled'
                                  value={trackingLinks.required}
                                  size='small'
                                  multiline
                                  fullWidth
                                  disabled
                                  sx={{
                                    wordBreak: 'break-all',
                                    overflowWrap: 'break-word'
                                  }}
                                />
                                <Button
                                  variant='text'
                                  color='primary'
                                  onClick={() => {
                                    setMessage('Copied to clipboard')
                                    setShowMessage(true)
                                    copyToClipboard(trackingLinks.required)
                                  }}
                                >
                                  Copy
                                </Button>
                              </Box>

                              {/* Connector Tracking Parameters - All */}
                              <Box marginTop={1}>
                                <Typography variant='body2' fontWeight='bold'>
                                  Tracking Parameters - All
                                </Typography>
                              </Box>
                              <Box display='flex' flexDirection='row' width='100%'>
                                <InputBase
                                  variant='filled'
                                  value={trackingLinks.all}
                                  size='small'
                                  multiline
                                  fullWidth
                                  disabled
                                  sx={{
                                    wordBreak: 'break-all',
                                    overflowWrap: 'break-word'
                                  }}
                                />
                                <Button
                                  variant='text'
                                  color='primary'
                                  onClick={() => {
                                    setMessage('Copied to clipboard')
                                    setShowMessage(true)
                                    copyToClipboard(trackingLinks.all)
                                  }}
                                >
                                  Copy
                                </Button>
                              </Box>

                            </Box>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <Typography variant='body2' color='textSecondary'>
                          No contact connectors have been created yet
                        </Typography>
                      </>
                    )}
                  </Box>
                )}

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

AffiliateTracking.propTypes = {}

export default AffiliateTracking