exports.ACTIVECAMPAIGN = 'activecampaign'
exports.AGILIRON = 'agiliron'
exports.AUTHORIZE_NET = 'authorize.net'
exports.BUYGOODS = 'buygoods'
exports.BUCKET_IO = 'bucket.io'
exports.CLICKFUNNELS = 'clickfunnels'
exports.CLICKBANK = 'clickbank'
exports.CUSTOM = 'custom'
exports.CONVERTBOX = 'convertbox'
exports.DIGISTORE24 = 'digistore24'
exports.DROPFUNNELS = 'dropfunnels'
exports.EASYWEBINAR = 'easywebinar'
exports.HIGHLEVEL = 'highlevel'
exports.JVZOO = 'jvzoo'
exports.KAJABI = 'kajabi'
exports.KARTRA = 'kartra'
exports.KEAP = 'keap'
exports.KIT = 'kit'
exports.KONNEKTIVE = 'konnektive'
exports.MAROPOST = 'maropost'
exports.MERCHANT_ONE = 'merchant_one'
exports.ONTRAPORT = 'ontraport'
exports.PAYPAL = 'paypal'
exports.SAMCART = 'samcart'
exports.SHOPIFY = 'shopify'
exports.STICKY = 'sticky'
exports.STRIPE = 'stripe'
exports.TENX_CRM = '10x_crm'
exports.THRIVECART = 'thrivecart'
exports.TYPEFORM = 'typeform'
exports.ULTRACART = 'ultracart'
exports.WARRIORPLUS = 'warriorplus'
exports.WOOCOMMERCE = 'woocommerce'
exports.WORDPRESS = 'wordpress'
exports.OTHER = 'other'