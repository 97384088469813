import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import maropostLogoImg from '../../media/maropost_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const INSERT_CONNECTOR_SUCCESS_MESSAGE = 'Connector created. Use the updated webhook URL in Maropost to load contact data.'
const DELETE_CONNECTOR_SUCCESS_MESSAGE = 'Connector removed. Contact data will no longer be loaded. Webhook URL has been updated.'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/maropost/`

const MaropostSource = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)
  const [showDeleteConnectorConfirmation, setShowDeleteConnectorConfirmation] = React.useState(false)

  const [isEditingConnector, setIsEditingConnector] = React.useState(false)

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isLoadingConnector,
    data: connector = {},
    refetch: refetchConnector
  } = useQuery(['connector-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}/connector`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
        .then(data => {
          if (data.exists) {
            return data.connector
          } else {
            return {}
          }
        })
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    if (!integration || !connector) return
    let webhookUrl
    if (integration) {
      webhookUrl = WEBHOOK_URL_BASE + uid + '/integration/' + id
      if (connector.id) {
        webhookUrl = WEBHOOK_URL_BASE + uid + '/integration/' + id + '/connector/' + connector.id + '/contact'
      }
    }
    setWebhookUrl(webhookUrl)
  }, [integration, connector])


  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/maropost/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const insertConnector = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      setIsEditingConnector(true)
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}/connector/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          config: {}
        })
      }).then(res => {
        setIsEditingConnector(false)
        setMessage(INSERT_CONNECTOR_SUCCESS_MESSAGE)
        setShowMessage(true)
        refetchConnector()
        return res.json()
      })
    })
  }

  const deleteConnector = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      setIsEditingConnector(true)
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}/connector/delete`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => {
        setIsEditingConnector(false)
        setShowDeleteConnectorConfirmation(false)
        setMessage(DELETE_CONNECTOR_SUCCESS_MESSAGE)
        setShowMessage(true)
        refetchConnector()
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='maropost_logo'
                    src={maropostLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    Maropost Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Connector */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Contact Connector
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display='block'>
                    <Typography variant='body2' paragraph>
                      Contact Connectors are used to store mappings of contact IDs and email addresses from your email service providers.
                      When using our affiliate ID tracking system, this allows LTV Numbers to match contacts based on their IDs
                      so sales can be attributed to the correct contact. This makes it possible to include affiliate sales in Lifetime Value
                      analysis and other insightful reports.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      It is recommended to decide whether you will be using the connector at the time of creating this integration. The reason is that webhook URL
                      you will add to your Maropost account will change when the connector is enabled. If you decide to enable the connector later, you will need to update
                      the webhook URL in your Maropost account.
                    </Typography>
                  </Box>
                  {isLoadingConnector ? (
                    <Skeleton variant='rect' width='100%' height={100} />
                  ) : (
                    <>
                      {connector.id ? (
                        <Box
                          display='inline-block'
                          marginTop={1}
                          marginBottom={1}
                          border={1}
                          borderColor={theme.palette.divider}
                          borderRadius={1}
                          padding={2}
                        >
                          <Typography variant='body2' display='block'>
                            <b>Status:</b> Enabled
                          </Typography>
                          <Typography variant='body2' display='block'>
                            <b>Created:</b> {new Date(connector.record_created_at).toLocaleString()}
                          </Typography>
                          <Button
                            variant='text'
                            color='error'
                            onClick={() => setShowDeleteConnectorConfirmation(true)}
                          >
                            Delete Connector
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <Typography variant='caption' display='block'>
                            <b>Status:</b> Disabled
                          </Typography>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => insertConnector()}
                          >
                            {isEditingConnector ? <CircularProgress color='inherit' size={24} /> : 'Attach Connector'}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Webhook URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display='block'>
                    <Typography variant='body2' paragraph>
                      To bring in contact data in real time, use the webhook URL below. You can set this URL in two places: for individual lists, or your entire account.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      If you would like to sync new contacts from specific lists, you can set the webhook URL by editing each list, and pasting the URL below in the <b>Post URL</b> field, then clicking Save.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      If you would like to sync new contacts from your entire account, across all lists, you can do this by clicking on the menu in the top right, then <b>Settings</b>.
                      On the Settings page, click <b>HTTP Post URL</b>. Paste the webhook URL below in the <b>HTTP Post URL field</b> and save changes.
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    {isLoadingIntegration || isLoadingConnector ? <Skeleton variant='text' width='100%' /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={webhookUrl}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltip}
                          onClose={() => setShowCopyTooltip(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(webhookUrl)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Delete Source Confirmation Dialog */}
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Connector Confirmation Dialog */}
      <Dialog
        open={showDeleteConnectorConfirmation}
        onClose={() => setShowDeleteConnectorConfirmation(false)}
      >
        <DialogTitle>
          Remove Contact Connector from <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will cause the current webhook URL to become invalid, preventing new contacts
            and their IDs from syncing to LTV Numbers. It will also delete all contact mappings
            that have been saved for this connector. This cannot be undone. If you are going to proceed, you should first delete
            the webhook from your Maropost account. Are you sure you want to remove the connector?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowDeleteConnectorConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => deleteConnector()}
          >
            {isEditingConnector ? <CircularProgress color='inherit' size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity='success'
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

MaropostSource.propTypes = {}

export default MaropostSource